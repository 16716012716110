<template>
  <SidePopup
    :title="title"
    v-on:close="onClose"
    @submit="onSubmit"
    :loading="loading"
    routeback="/fornecedor/comercial/lista-compras"
  >
    <div>
      <FormField label="Nome" v-model="form.nome" :validation="$v.form.nome" />
      <SelectCliente
        v-show="!form.idsegmentocliente"
        placeholder="Pesquisar pelo nome, cpf, cnpj ou e-mail"
        label="Cliente"
        v-model="client"
        :endpoint="endpointClient"
        queryname="search"
        keyid="email"
        keyvalue="nome"
        :multiple="false"
        :clearable="true"
        @change="onChangeClient"
      >
        <template v-slot:item="{ item }">
          <div class="d-flex flex-column align-start">
            <p style="margin-bottom:0!important">{{ item.nome }}</p>
            <small v-if="item.cpf"> CPF/CNPJ: {{ item.cpf }} </small>
          </div>
        </template>
      </SelectCliente>
      <FormSelect
        v-show="!form.idcliente"
        label="Segmento do cliente"
        :options="segments"
        :clearable="true"
        v-model="form.idsegmentocliente"
      />
      <FormSwitch label="Exibir" v-model="form.ativo" />
      <LineDivider><h4>Produtos</h4></LineDivider>
      <SelectProduto v-model="form.produtos" :validation="$v.form.produtos" />
    </div>
    <template v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import FormSwitch from '@/components/form/FormSwitch'
import FormSelect from '@/components/form/FormSelect'
import FormField from '@/components/form/FormField'
import LineDivider from '@/components/LineDivider'
import FormButton from '@/components/form/FormButton'
import SelectCliente from '@/components/select/SelectCliente'
import SelectProduto from '@/components/select/SelectProduto'
import { SEGMENTO_CLIENTE_LIST } from '@/store/actions/segmento'
import { mapGetters } from 'vuex'
import { required, numeric } from 'vuelidate/lib/validators'
import {
  LISTA_COMPRAS_CREATE,
  LISTA_COMPRAS_LOAD
} from '@/store/actions/listaCompras'
import * as _ from 'lodash'
export default {
  name: 'ListaComprasForm',
  components: {
    SidePopup,
    FormSelect,
    FormField,
    SelectProduto,
    SelectCliente,
    LineDivider,
    FormButton,
    FormSwitch
  },
  validations: {
    form: {
      nome: { required },
      produtos: {
        $each: {
          quantidade: {
            required,
            numeric
          }
        }
      }
    }
  },
  data: () => ({
    mode: 'add',
    title: 'Nova lista de compras',
    form: {
      nome: '',
      idcliente: null,
      idsegmentocliente: null,
      produtos: [],
      ativo: true
    },
    loading: false,
    client: null,
    searchClient: ''
  }),
  computed: {
    ...mapGetters(['getFornecedorId']),
    endpointClient() {
      return `/api/v4/client/${this.getFornecedorId}/search/options?page=1&perpage=20`
    },
    segments() {
      return (this.$store.getters.getSegmentos || []).map(s =>
        this.getDataToSelect(s, 'nome', 'idsegmentocliente')
      )
    }
  },
  created() {
    this.getSegments()
    let idRoute = this.$route.params.id
    if (idRoute !== 'novo') {
      this.mode = 'edit'
      this.getData(idRoute)
    }
  },
  methods: {
    getSegments() {
      this.$store.dispatch(SEGMENTO_CLIENTE_LIST, {
        idfornecedor: this.getFornecedorId
      })
    },
    getData(id) {
      this.loading = true
      this.$store
        .dispatch(LISTA_COMPRAS_LOAD, id)
        .then(resp => {
          this.loadData(resp)
          this.loading = false
        })
        .catch(() => (this.loading = false))
    },
    getDataToSelect(item, labelField, valueField) {
      return item
        ? {
            text: item[labelField],
            value: item[valueField]
          }
        : null
    },
    loadData(data) {
      this.form = { ...data, produtos: data.produtos || [] }
      if (data.idcliente) {
        this.client = {
          idcliente: data.idcliente,
          nome: data.cliente_nome,
          email: data.cliente_email
        }
      }
    },
    onChangeClient() {
      this.form.idcliente = _.get(this.client, 'idcliente') || null
    },
    onSubmit() {
      if (!this.form.produtos.length) {
        this.$vueOnToast.pop(
          'warning',
          'Não é possível criar uma lista de compras vazia'
        )
        return
      } else if (this.form.produtos.filter(p => p.produto == null).length) {
        this.$vueOnToast.pop(
          'warning',
          'Não é possível criar uma lista de compras sem produtos selecionados'
        )
        return
      }

      const produtos = this.form.produtos.map(p => {
        return {
          idcadastroextraproduto: _.get(p, 'produto.idcadastroextraproduto'),
          quantidade: p.quantidade
        }
      })

      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      const payload = { ...this.form, produtos }
      this.$store
        .dispatch(LISTA_COMPRAS_CREATE, payload)
        .then(() => {
          this.onClose(true)
          this.$vueOnToast.pop(
            'success',
            'Lista de compras cadastrada com sucesso'
          )
        })
        .catch(() => {
          this.$vueOnToast.pop(
            'error',
            'Falha ao tentar salvar lista de compras'
          )
        })
    },
    onClose(routeback = false) {
      this.$emit('close')
      if (routeback) {
        this.$router.replace('/fornecedor/comercial/lista-compras')
      }
    }
  }
}
</script>
